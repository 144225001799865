import styles from '../../styles/ReviewCard.module.css'
import { ReviewProps } from "../../lib/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Link from "next/link";
import ConditionalLink from "../ConditionalLink";

const ReviewCard = (props: ReviewProps) => {

  return(
    <ConditionalLink asLink={!!props.url} link={props.url || ''}>
      <div className={styles.container}>
        <div className='d-flex align-items-center gap-1'>
          {Array(props.stars).fill({}).map((star, index) => (
            <FontAwesomeIcon key={index} icon={faStar} className={styles.star} />
          ))}
        </div>
        <div className='d-flex flex-column justify-content-between'>
          <h5 className={`${styles.reviewBody} fw-light mb-4`}>{props.body}</h5>
          <div className='text-muted'>{props.name}</div>
        </div>
      </div>
    </ConditionalLink>
  )
}

export default ReviewCard