import styles from '../../styles/Card.module.css'
import { CardImagePlacement } from "../../lib/types";
import { CARD_IMAGE_PLACEMENTS, ctaClickSources } from "../../lib/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Pill from "../Pill";
import Link from "next/link";
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { clickedCTA } from "../../store/actions/events";
import Image from 'next/image';

const Card = (
  props:
    {
      imagePlacement?: CardImagePlacement,
      tags?: { name: string, id: number }[],
      image?: string,
      title?: string,
      body?: string,
      buttonText: string,
      link?: string
    }
) => {

  const dispatch = useDispatch()
  const router = useRouter()

  if ( props.body === '' ) return <></>

  return (
      <Link href={props.link ? props.link : '#'}>
        <div
          className={`clickable card bg-gray-1 p-0 h-100 `}
          onClick={() => dispatch(clickedCTA({
            name: ctaClickSources.blog.readMore.name,
            url: router.asPath,
            blogPostTitle: props.title
          }))}
        >
          {props.imagePlacement === CARD_IMAGE_PLACEMENTS.TOP_FULL ? (
            <div className={styles.fullImage}>
              <Image src={props.image || ''} style={{objectFit:"cover"}} fill alt="" />
            </div>
          ) : <></>}
          <div className='p-3 p-md-4 pt-md-3 h-100'>
            <Row className='h-100'>
              <Col sm='12'>
                {props.imagePlacement === CARD_IMAGE_PLACEMENTS.TOP ? (
                  <div className='px-3 pt-3 w-100'>
                    <Image height={260} width={347} src={props.image || ''} className={[styles.image, 'img-fluid'].join(' ')} alt='' />
                  </div>
                ) : <></>}
                  <div>
                    {props.tags && props.tags.length > 0 ? (
                      <div className={`mb-3 ${styles.tagContainer}`}>
                        {props.tags.map((tag) => (
                          <Pill key={tag.id} content={tag.name} bgColor='bg-gray-10' />
                        ))}
                      </div>
                    ) : <></>}
                    <h5 className='mb-2'>{props.title}</h5>
                    <div className={'caption mb-3 text-muted'}>{props.body}</div>
                  </div>

              </Col>
              <Col sm='12' className=' align-self-end'>
                <div className={styles.button}>
                  <div className='d-inline-flex align-items-center gap-2 clickable'>
                    <div className='text-decoration-underline'>{props.buttonText}</div>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Link>
  )
}

export default Card
