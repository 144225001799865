import styles from '../../styles/BoxIcon.module.css'
import Image from "next/image";

const BoxIcon = (props: { icon: string, name: string, small?: boolean }) => {
  return(
    <div className={styles.container}>
      <div className={`${styles.icon} ${props.small ? styles.iconSmall : ''}`}>
        <Image src={props.icon} alt='' width={props.small ? 50 : 100} height={props.small ? 50 : 100} />
      </div>
      <h4 className={`position-absolute top-100 start-50 translate-middle-y px-2 py-1 rounded ${styles.name}`}>{props.name}</h4>
    </div>
  )
}

export default BoxIcon