import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export function SubscriptionIncludesItem(props: { readonly text: string }) {
  return (
    <div className="d-flex align-items-center">
      <FontAwesomeIcon
        className="mx-2 text-center text-success-green"
        icon={faCircleCheck}
        size="lg"
      />
      <p className="h5">{props.text}</p>
    </div>
  );
}
