import styles from '../../styles/Featurette.module.css'
import { FEATURETTE_IMAGE_PLACEMENTS } from "../../lib/constants";
import { Col, Container, Row } from 'react-bootstrap';
import Image from "next/image";

const Featurette = (props: {
  id?: string,
  image: string,
  imagePlacement: keyof typeof FEATURETTE_IMAGE_PLACEMENTS,
  children: React.ReactNode,
  backgroundColor?: string,
  mobileFullscreenImage?: boolean
}
) => {

  if (!props.image && !props.children) return <></>

  return(

    <div id={props.id} className="bg-light" style={{...(props.backgroundColor ? { backgroundColor: props.backgroundColor } : {})}}>
      <Container className={`${props.mobileFullscreenImage ? 'py-0' : 'py-5'} py-md-5`} fluid='md'>
        <Row className={props.mobileFullscreenImage ? 'px-0' : 'px-5'}>
          <Col className={`align-self-center mb-4 px-0 px-md-3 ${props.imagePlacement === FEATURETTE_IMAGE_PLACEMENTS.RIGHT ? 'order-2':'order-1'}`} md={6}>
            <Image src={props.image} className={`${styles.image} img-fluid mh-100 rounded-3`} alt='' width={490} height={490} />
          </Col>
          <Col className="align-self-center mb-4 order-2 order-md-1 px-4" md={6}>
            {props.children}
          </Col>
        </Row>
      </Container>
    </div>

  )
}

export default Featurette
