import Link from "next/link";

const ConditionalLink = (props: { asLink: boolean, link: string, children: React.ReactNode }) => {
  if (props.asLink) {
    const external = props.link.startsWith("https://") && !props.link.startsWith("https://tribu-box.com")
    if (external) {
      return (
        <a href={props.link} rel="noreferrer" target="_blank" style={{ color: 'inherit',  cursor: 'pointer' }}>
          {props.children}
        </a>
      )
    }
    return (
      <Link href={props.link} passHref>
        <div style={{ cursor: 'pointer' }}>
          {props.children}
        </div>
      </Link>
    )
  } else {
    return (
      <>
        {props.children}
      </>
    )
  }
}

export default ConditionalLink