import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import Image from 'next/image';
import styles from '../../styles/BoxPreview.module.css';
import Pill from '../Pill';
import { clickedCTA } from '../../store/actions/events';
import { ctaClickSources } from '../../lib/constants';
import BoxIcon from './BoxIcon';

export function BoxPreview(props: {
  readonly image: string;
  readonly icon: string;
  readonly name: string;
  readonly boxUrl: string;
  readonly label?: string;
  readonly body?: string;
}) {
  const router = useRouter();
  const dispatch = useDispatch();

  return (
    <Link
      passHref
      href={{
        pathname: `/our-boxes/${props.boxUrl}`
      }}
    >
      <div
        className={`clickable card rounded-5 p-0 position-relative ${styles.container}`}
        onClick={() =>
          dispatch(
            clickedCTA({
              name: ctaClickSources.starterBoxSection.boxPreview.name,
              url: router.asPath,
              boxTitle: props.name,
            })
          )
        }
      >
        <div className={styles.fullImage}>
          <Image
            src={props.image}
            style={{ objectFit: 'cover' }}
            width={274}
            height={233}
            alt=""
          />

          {props.label && (
            <div className="position-absolute top-0 end-0 mt-3 me-3">
              <Pill content={props.label} variant="secondary" />
            </div>
          )}
        </div>
        <div className={styles.content}>
          <div className={styles.boxIcon}>
            <BoxIcon small icon={props.icon} name={props.name} />
          </div>
          <div className="pt-5">
            <div className="caption text-muted text-start max-line-3">
              {props.body}
            </div>
          </div>
          <span className={styles.button}>Learn more</span>
        </div>
      </div>
    </Link>
  );
}
