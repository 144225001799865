import styles from '../../styles/QuoteCard.module.css'
import { QuoteProps } from "../../lib/types";
import Image from "next/image";

const QuoteCard = (props: QuoteProps) => {
  return(
    <div className={styles.container}>
      {props.image &&
        <div className={styles.image}>
          <Image className='img-fluid mh-100' src={props.image} alt='' height={200} width={200} />
        </div>
      }
      <div className={styles.body}>{props.body}</div>
    </div>
  )
}

export default QuoteCard