import React from 'react';
import type { GetServerSideProps, NextPage } from 'next';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import Card from '../components/Card';
import {
  CARD_IMAGE_PLACEMENTS,
  PAGE_SECTIONS,
  FEATURETTE_IMAGE_PLACEMENTS,
  ctaClickSources,
} from '../lib/constants';
import Hero from '../components/Hero';
import { SubscriptionsSection } from '../components/SubscriptionsSection';
import PageSection from '../components/PageSection';
import styles from '../styles/Home.module.css';
import Carousel from '../components/Carousel';
import QuoteCard from '../components/QuoteCard';
import {
  type ArticleDTO,
  type SubscriptionData,
  type HeroDTO,
  type HowItWorksDTO,
  type PageSectionDTO,
  type ReviewData,
  type QuoteDTO,
  type HowItWorksStepDTO,
  type Locale,
  type CmsBoxDto,
} from '../lib/types';
import Featurette from '../components/Featurette';
import FeaturetteTextContent from '../components/Featurette/FeaturetteTextContent';
import ReviewCard from '../components/ReviewCard';
import {
  mapPageSectionData,
  mapGiftCardSectionData,
  mapHeroData,
  mapHowItWorksData,
  mapQuoteData,
  mapArticleData,
  mapStarterBoxSectionData,
} from '../lib/utils/mappers';
import { mapSubscriptionsIncludesData } from '../lib/utils/mappers/map-subscriptions-includes-data';
import { mapSubscriptionData } from '../lib/utils/mappers/map-subscription-data';
import { mapSubscriptionSectionData } from '../lib/utils/mappers/map-subscription-section-data';
import { mapReviewData } from '../lib/utils/mappers/map-review-data';
import { mapBoxData } from '../lib/utils/mappers/map-box-data';
import HowItWorksStep from '../components/HowItWorksStep';
import SelectStarterBoxSection from '../components/SelectStarterBoxSection';
import api from '../lib/utils/api';
import cmsEndpoints from '../lib/utils/cmsEndpoints';
import { PageHead } from '../components/PageHead';
import { clickedCTA } from '../store/actions/events';

export const getServerSideProps: GetServerSideProps = async (context) => {
  context.res.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=59'
  );

  const locale = context.locale as Locale;

  const [
    homescreen,
    reviewsData,
    quotesData,
    articlesData,
    subscriptionsData,
    boxesData,
  ] = await Promise.all([
    api.get(cmsEndpoints.homescreen()),
    api.get(cmsEndpoints.reviews()),
    api.get(cmsEndpoints.quotes()),
    api.get(cmsEndpoints.blogPost({ offset: 0, limit: 3 })),
    api.get(cmsEndpoints.subscriptions()),
    api.get(cmsEndpoints.boxes()),
  ]);

  const homescreenAttributes = homescreen?.data.data.attributes ?? {};
  const blocks = homescreenAttributes.blocks ?? [];

  const hero: HeroDTO = mapHeroData(homescreenAttributes, locale);

  const howItWorksData = blocks.find(
    (block: any) => block.__component === PAGE_SECTIONS.HOW_IT_WORKS
  );
  const howItWorks: HowItWorksDTO = mapHowItWorksData(howItWorksData, locale);

  const starterBoxSectionData = blocks.find(
    (block: any) => block.__component === PAGE_SECTIONS.SELECT_STARTER_BOX
  );
  const starterBoxSection = mapStarterBoxSectionData(
    starterBoxSectionData,
    locale
  );

  const subscriptionSectionData = blocks.find(
    (block: any) => block.__component === PAGE_SECTIONS.SUBSCRIPTIONS
  );
  const subscriptionSection = mapSubscriptionSectionData(
    subscriptionSectionData,
    locale
  );

  const giftCardData = blocks.find(
    (block: any) => block.__component === PAGE_SECTIONS.GIFT_CARD
  );
  const giftCard = mapGiftCardSectionData(giftCardData, locale);

  const articleSectionData = blocks.find(
    (block: any) => block.__component === PAGE_SECTIONS.ARTICLES
  );
  const articleSection: PageSectionDTO = mapPageSectionData(
    articleSectionData,
    locale
  );
  const articles = (articlesData?.data?.data ?? []).map((article: any) =>
    mapArticleData(article.attributes, locale)
  );

  const reviewSectionData = blocks.find(
    (block: any) => block.__component === PAGE_SECTIONS.REVIEWS
  );
  const reviewSection: PageSectionDTO = mapPageSectionData(
    reviewSectionData,
    locale
  );
  const reviews: ReviewData[] = (reviewsData?.data?.data ?? []).map(
    (review: any) => mapReviewData(review, locale)
  );

  const subscriptions: SubscriptionData[] = (
    subscriptionsData?.data?.data?.attributes?.options ?? []
  ).map((subscription: any) => mapSubscriptionData(subscription, locale));
  const subscriptionsIncludes: string[] = (
    subscriptionsData?.data?.data?.attributes?.includes ?? []
  ).map((item: any) =>
    mapSubscriptionsIncludesData(item, context.locale as Locale)
  );

  const quoteSectionData = blocks.find(
    (block: any) => block.__component === PAGE_SECTIONS.QUOTES
  );
  const quoteSection: PageSectionDTO = mapPageSectionData(
    quoteSectionData,
    locale
  );
  const quotes: QuoteDTO[] = (quotesData?.data?.data ?? []).map((quote: any) =>
    mapQuoteData(quote?.attributes, locale)
  );

  const boxes = (boxesData?.data?.data ?? []).map((box: CmsBoxDto) =>
    mapBoxData(box, locale)
  );
  const sortedBoxes = boxes
    .filter((item) => item?.ageGroup?.maxMonths)
    .sort((a, b) => a.ageGroup.maxMonths - b.ageGroup.maxMonths);

  return {
    props: {
      blocksOrder: blocks.map((block: any) => block.__component),
      hero,
      howItWorks,
      starterBoxSection,
      subscriptionSection,
      subscriptions,
      subscriptionsIncludes,
      giftCard,
      articleSection,
      articles,
      reviewSection,
      reviews,
      quoteSection,
      quotes,
      boxes: sortedBoxes,
    },
  };
};

const Home: NextPage = (props: any) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const pageSections = {
    [PAGE_SECTIONS.SELECT_STARTER_BOX]: (blockIndex: number) => (
      <SelectStarterBoxSection
        key={blockIndex}
        ageFilters={props.starterBoxSection.ageFilters}
        title={props.starterBoxSection.title}
        buttonLabel={props.starterBoxSection.buttonLabel}
        boxes={props.boxes}
        backgroundPattern={props.starterBoxSection.backgroundPattern}
      />
    ),
    [PAGE_SECTIONS.HOW_IT_WORKS]: (blockIndex: number) => (
      <PageSection
        key={blockIndex}
        title={props.howItWorks.title}
        backgroundColor="#FAFCFC"
      >
        <div className={styles.howItWorksSteps}>
          {props.howItWorks.howItWorksStep.map(
            (step: HowItWorksStepDTO, index: number) => (
              <HowItWorksStep
                key={index}
                icon={step.icon}
                title={step.title}
                description={step.description}
              />
            )
          )}
        </div>
      </PageSection>
    ),
    [PAGE_SECTIONS.SUBSCRIPTIONS]: (blockIndex: number) => (
      <SubscriptionsSection
        key={blockIndex}
        backgroundImage={props.subscriptionSection.backgroundImage}
        title={props.subscriptionSection.title}
        subtitle={props.subscriptionSection.subtitle}
        ctaText={props.subscriptionSection.ctaText}
        ctaLink={props.subscriptionSection.ctaLink}
        subscriptionsIncludes={props.subscriptionsIncludes}
        subscriptions={props.subscriptions}
      />
    ),
    [PAGE_SECTIONS.GIFT_CARD]: (blockIndex: number) => (
      <Featurette
        key={blockIndex}
        id="tribu-shop-featurette"
        image={props.giftCard.image}
        imagePlacement={FEATURETTE_IMAGE_PLACEMENTS.RIGHT}
        backgroundColor="#FAFCFC"
      >
        <div className="h-100 w-100 d-flex align-items-center justify-content-center justify-content-md-start">
          <FeaturetteTextContent
            title={props.giftCard.title}
            body={props.giftCard.description}
            buttonText={props.giftCard.buttonText}
            link="https://shop.tribu-box.com/"
            onCTAClick={() =>
              dispatch(
                clickedCTA({
                  name: ctaClickSources.giftCardSection.seeAll.name,
                  url: router.asPath,
                })
              )
            }
          />
        </div>
      </Featurette>
    ),
    [PAGE_SECTIONS.ARTICLES]: (blockIndex: number) => (
      <PageSection
        key={blockIndex}
        title={props.articleSection.title}
        image={props.articleSection.titleImage}
      >
        <div className={styles.articles}>
          {props.articles.map((article: ArticleDTO, index: number) => (
            <div key={index} className={styles.article}>
              <Card
                title={article.title}
                body={article.intro}
                buttonText="Read more"
                image={article.mainImage}
                imagePlacement={CARD_IMAGE_PLACEMENTS.TOP_FULL}
                tags={article.categories}
                link={`/blog/${article.slug}`}
              />
            </div>
          ))}
        </div>
      </PageSection>
    ),
    [PAGE_SECTIONS.REVIEWS]: (blockIndex: number) => (
      <PageSection
        key={blockIndex}
        title={props.reviewSection.title}
        image={props.reviewSection.titleImage}
        backgroundColor={props.reviewSection.backgroundColor}
      >
        <div className={`reviewSection ${styles.reviewsContainer}`}>
          <Carousel
            centerMode
            dots
            arrows
            draggable
            slidesToShow={3}
            slidesToShowXl={2}
            slidesToShowLg={2}
            slidesToShowMd={1}
            slidesToShowSm={1}
            infinite={props.reviews.length > 3}
            centerPadding="16px"
            variant="simple"
          >
            {props.reviews.map((review: ReviewData, index: number) => (
              <ReviewCard
                key={index}
                name={review.name}
                stars={review.stars}
                body={review.review}
                url={review.url}
              />
            ))}
          </Carousel>
        </div>
      </PageSection>
    ),
    [PAGE_SECTIONS.QUOTES]: (blockIndex: number) => (
      <PageSection
        key={blockIndex}
        title={props.quoteSection.title}
        image={props.quoteSection.titleImage}
      >
        <div
          style={{
            ...(props.quotes.length > 0 ? { paddingBottom: '7rem' } : {}),
          }}
        >
          <Carousel
            centerMode
            dots
            arrows
            slidesToShow={5}
            slidesToShowXl={4}
            slidesToShowLg={3}
            slidesToShowMd={2}
            slidesToShowSm={1}
            infinite={props.quotes.length > 5}
            variant="simple"
          >
            {props.quotes.map((quote: QuoteDTO, index: number) => (
              <QuoteCard key={index} image={quote.logo} body={quote.quote} />
            ))}
          </Carousel>
        </div>
      </PageSection>
    ),
  };

  return (
    <>
      <PageHead />
      <Hero
        title={props.hero.title}
        subtitle={props.hero.subtitle}
        ctaText={props.hero.ctaText}
        ctaLink={props.hero.ctaLink}
        backgroundImage={props.hero.backgroundImage}
        mobileBackgroundImage={props.hero.mobileBackgroundImage}
        checks={props.hero.checks}
        ratingsText={props.hero.ratingsText}
      />
      {props.blocksOrder.map(
        (block: string, index: number) =>
          pageSections[block] && pageSections[block](index)
      )}
    </>
  );
};

export default Home;
