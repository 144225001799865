import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import styles from '../../styles/SubscriptionsSection.module.css';
import { clickedCTA } from '../../store/actions/events';
import { ctaClickSources } from '../../lib/constants';
import { HeaderWithDescription } from '../HeaderWithDescription';
import { SubscriptionIncludesList } from '../SubscriptionIncludesList';
import { SubscriptionsList } from '../SubscriptionsList';
import { type SubscriptionData } from '../../lib/types';

export function SubscriptionsSection(props: {
  readonly title: string;
  readonly subtitle: string;
  readonly backgroundImage?: string;
  readonly backgroundColor?: string;
  readonly subscriptions: SubscriptionData[];
  readonly ctaText?: string;
  readonly ctaLink?: string;
  readonly subscriptionsIncludes: string[];
}) {
  const router = useRouter();
  const dispatch = useDispatch();

  return (
    <div
      className={styles.container}
      style={{
        ...(props.backgroundImage
          ? { backgroundImage: `url(${props.backgroundImage})` }
          : {}),
        ...(props.backgroundColor
          ? { backgroundColor: props.backgroundColor }
          : {}),
      }}
    >
      <Container fluid="xl" className="pt-4 pb-5">
        <HeaderWithDescription
          title={props.title}
          description={props.subtitle}
          className="text-center"
        />
        <SubscriptionIncludesList
          className="my-5"
          items={props.subscriptionsIncludes}
        />
        <SubscriptionsList subscriptions={props.subscriptions} />
        <Row>
          <Col className="text-center">
            {props.ctaText && props.ctaLink && (
              <Link href={props.ctaLink}>
                <Button
                  className="mt-5"
                  size="lg"
                  onClick={() =>
                    dispatch(
                      clickedCTA({
                        name: ctaClickSources.subscriptionSection.name,
                        url: router.asPath,
                      })
                    )
                  }
                >
                  <span className="me-3">{props.ctaText}</span>
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </Link>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
