import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import type Slider from 'react-slick';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import Carousel from '../Carousel';
import { BoxPreview } from '../Box/BoxPreview';
import { type BoxData } from '../../lib/types/data/box-data';
import { type AgeGroupData } from '../../lib/types';
import Pill from '../Pill';
import styles from '../../styles/SelectStarterBoxSection.module.css';
import { clickedCTA } from '../../store/actions/events';
import { ctaClickSources } from '../../lib/constants';

function SelectStarterBoxSection(props: {
  readonly title?: string;
  readonly subtitle?: string;
  readonly buttonLabel?: string;
  readonly backgroundPattern?: string;
  readonly ageFilters: AgeGroupData[];
  readonly boxes: BoxData[];
}) {
  const router = useRouter();
  const dispatch = useDispatch();

  const filteredAgeGroups = useMemo(() => {
    const existingAgeGroups = props.boxes.map((box) => {
      return {
        minMonths: box.ageGroup?.minMonths || 0,
        maxMonths: box.ageGroup?.maxMonths,
      };
    });
    return props.ageFilters.filter(
      (ageFilter) =>
        ageFilter.maxMonths &&
        existingAgeGroups.some(
          (existingAgeGroup) =>
            existingAgeGroup.minMonths === ageFilter.minMonths ||
            existingAgeGroup.maxMonths === ageFilter.maxMonths
        )
    );
  }, [props.ageFilters, props.boxes]);

  const sliderRef = useRef<Slider>();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentAgeGroup, setCurrentAgeGroup] = useState({
    minMonths: props.boxes[0].ageGroup?.minMonths || 0,
    maxMonths: props.boxes[0].ageGroup?.maxMonths,
  });

  const handleAgeGroupSelectorClick = (
    minMonths?: number,
    maxMonths?: number
  ) => {
    if (!maxMonths) return;
    setCurrentAgeGroup({
      minMonths: minMonths || 0,
      maxMonths,
    });
    const _index = props.boxes.reduce((result, box, index) => {
      if (
        box.ageGroup?.minMonths &&
        minMonths &&
        box.ageGroup?.minMonths <= minMonths
      )
        return index;
      return result;
    }, 0);
    setCurrentSlide(_index);
  };

  useEffect(() => {
    if (sliderRef.current) sliderRef.current?.slickGoTo(currentSlide, false);
    setCurrentAgeGroup({
      minMonths:
        props.boxes?.[Math.round(currentSlide)]?.ageGroup?.minMonths || 0,
      maxMonths:
        props.boxes?.[Math.round(currentSlide)]?.ageGroup?.maxMonths || 0,
    });
  }, [currentSlide, props.boxes]);

  return (
    <div className={styles.container}>
      <Row>
        <Col className="text-center pt-4">
          {props.title && <h2>{props.title}</h2>}
          <div className="d-flex justify-content-center gap-4 py-4">
            {filteredAgeGroups.map((filter) => {
              const selected =
                currentAgeGroup.minMonths >= (filter.minMonths || 0) &&
                currentAgeGroup.maxMonths &&
                filter.maxMonths &&
                currentAgeGroup.maxMonths <= filter.maxMonths;
              return (
                <Pill
                  key={filter.id + filter.displayName}
                  content={filter.displayName ?? ''}
                  variant={
                    selected ? 'secondary-bold' : 'secondary-transparent'
                  }
                  bgColor={selected ? 'bg-gray-5' : 'bg-white'}
                  onClick={() => {
                    handleAgeGroupSelectorClick(
                      filter.minMonths,
                      filter.maxMonths
                    );
                  }}
                />
              );
            })}
          </div>
        </Col>
      </Row>
      <Row className="px-0 pb-3">
        <Col>
          <Carousel
            ref={sliderRef}
            centerModeMobile
            arrows
            draggable
            slidesToShow={4.5}
            slidesToShowXl={4.5}
            slidesToShowLg={3.5}
            slidesToShowMd={2.5}
            slidesToShowSm={1}
            dots={false}
            infinite={false}
            centerMode={false}
            centerPadding="48px"
            variant="box-selector"
            setCurrentSlide={setCurrentSlide}
          >
            {props.boxes.map((box: BoxData, index: number) => (
              <BoxPreview
                key={index}
                image={box.headerImage}
                icon={box.shopifyImageUrl}
                name={box.shortTitle}
                label={box?.ageGroup?.displayName ?? ''}
                body={box.description}
                boxUrl={box.url}
              />
            ))}
          </Carousel>
        </Col>
        <div className="d-flex justify-content-center mb-4">
          <Link href="/our-boxes">
            <Button
              size="lg"
              className="d-flex align-items-center mt-4"
              onClick={() =>
                dispatch(
                  clickedCTA({
                    name: ctaClickSources.starterBoxSection.sectionCTA.name,
                    url: router.asPath,
                  })
                )
              }
            >
              {props.buttonLabel}{' '}
              <FontAwesomeIcon className="ms-2" icon={faArrowRight} />
            </Button>
          </Link>
        </div>
      </Row>
    </div>
  );
}

export default SelectStarterBoxSection;
