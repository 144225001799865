import styles from '../../styles/Featurette.module.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import Link from 'next/link';

const FeaturetteTextContent = (props: {
  title?: string,
  subtitle?: string,
  body?: string,
  buttonText?: string,
  isCTA?: boolean,
  link?: string
  onCTAClick?: any
}
) => {
  return (
    <div>
      {props.subtitle && <div className={[styles.subtitle, 'mb-2'].join(' ')}>{props.subtitle}</div>}
      {props.title && <h1 className='mb-4'>{props.title}</h1>}
      {props.body && <div className='mb-4 text-muted'>{props.body}</div>}
      {props.buttonText && props.link && (
        <Link href={props.link}>{
          props.isCTA ? (
            <Button
              className={[styles.button, 'mt-4 d-flex align-items-center justify-content-center'].join(' ')}
              onClick={() => props.onCTAClick && props.onCTAClick()}
            >
              <div className='me-3'>{props.buttonText}</div>
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          ) : (
            <div className='d-inline-flex align-items-center gap-2 clickable'>
              <div
                className='text-decoration-underline fw-bold'
                onClick={() => props.onCTAClick && props.onCTAClick()}
              >{props.buttonText}</div>
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
          )}
        </Link>)
      }
    </div>
  )
}

export default FeaturetteTextContent