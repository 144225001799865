import styles from '../../styles/HeroRatings.module.css';

export default function HeroRatings({ ratingsText }: { ratingsText: string }) {
  return (
    <a
      target="_blank"
      href="https://www.reviews.io/company-reviews/store/tribu-box.com"
    >
      <img
        className={styles.ratingsImage}
        alt=""
        src="https://media.reviews.co.uk/badge/tribu-box.com.png?float=left"
      />
      <div className={styles.ratingsText}>{ratingsText}</div>
    </a>
  );
}