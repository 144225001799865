import React from 'react';
import { SubscriptionIncludesItem } from '../SubscriptionIncludesItem';
import styles from '../../styles/SubscriptionIncludes.module.css';

export function SubscriptionIncludesList(props: {
  readonly items: string[];
  readonly className?: string;
}) {
  if (!props.items || props.items.length === 0) {
    return null;
  }

  return (
    <div className={`${props.className} ${styles.list} my-3`}>
      {props.items.map((item: string) => (
        <SubscriptionIncludesItem key={item} text={item} />
      ))}
    </div>
  );
}
